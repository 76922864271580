import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Song } from '../../models/song';
import { Router } from '@angular/router';
import { HttpService } from '../../services/http.service';

/**
 * @title Table with filtering
 */
@Component({
  selector: 'app-list-song',
  templateUrl: './list-song.component.html',
  styleUrls: ['./list-song.component.css']
})
export class ListSongComponent {


  // -----------------------------------------------------------------------------------
  // variables 
  // -----------------------------------------------------------------------------------
  isClick: boolean = false;
  currentSong: string;
  searchWord: string = ''

  // show columns in table
  displayedColumns: string[] = ['tytul'];

  // data collection of songs
  dataSource = new MatTableDataSource<Song[]>();


  // -----------------------------------------------------------------------------------
  // constructor
  // -----------------------------------------------------------------------------------
  constructor(
    private router: Router,
    private httpService: HttpService
  ) {
    this.httpService.getSongs()
      .subscribe((songs) => {
        this.dataSource.data = songs as any;
      });

  }

  // -----------------------------------------------------------------------------------
  // click on song
  // -----------------------------------------------------------------------------------
  onRowClicked(row) {
    // this.router.navigate(['/song/' + row.id]);
    this.currentSong = this.formatSong(row.tekst.split('\n').join('<br>'));
    this.isClick = true
  }

  // -----------------------------------------------------------------------------------
  // search song
  // -----------------------------------------------------------------------------------
  applyFilter(filterValue: string) {
    this.searchWord = filterValue
    this.dataSource.filter = this.searchWord.trim().toLowerCase();
  }

  // -----------------------------------------------------------------------------------
  // chord formatting
  // -----------------------------------------------------------------------------------
  formatSong(songText: string): string {

    // variables
    let tmpTextSong = '';
    let char = 0;
    let tag = false;
    let tag2 = false;

    // const
    const column = 59;


    for (let x = 0; x < songText.length; x++) {

      if (songText.charAt(x) === '<') tag = true;
      if (songText.charAt(x) === '>') tag = false;

      if (char < column && songText.charAt(x) !== '>') {
        tmpTextSong += songText.charAt(x);
        char++;
      } else {

        tmpTextSong += songText.charAt(x);
        if (char < column && songText.charAt(x) === '>') {
          char = 0;
        }

        if (char === column && !tag) {
          tmpTextSong += '<mark>';
          tag2 = false;
          char++;
        }

        if (char === column && tag) {
          tag2 = true;
        }

        if (tag2 && songText.charAt(x) === '>') {
          tmpTextSong += '<mark>';
          tag2 = false;
        }

        if (songText.charAt(x) !== '>' && char > column) {
          char++;
        }

        if (songText.charAt(x) === '>' && char > column) {
          tmpTextSong += '</mark>';
          char = 0;
        }
      }
    }
    return tmpTextSong;
  }
  // -----------------------------------------------------------------------------------
}

