import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListSongComponent } from './components/list-song/list-song.component';
import { ViewSongComponent } from './components/view-song/view-song.component';


const routes: Routes = [
  { path: '', component: ListSongComponent },
  { path: 'song/:id', component: ViewSongComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
