import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Song } from '../models/song';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

// -----------------------------------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  // -----------------------------------------------------------------------------------
  // variables
  // -----------------------------------------------------------------------------------
  params: HttpParams;


  // -----------------------------------------------------------------------------------
  // constructor with DI
  // -----------------------------------------------------------------------------------
  constructor(
    private http: HttpClient
  ) {
    this.params = new HttpParams().append('key', environment.serverParameters.key);
  }

  // ------------------------------------------------------------------------
  // get all songs from server
  // ------------------------------------------------------------------------
  getSongs(): Observable<Song[]> {
    return this.http.get<Song[]>(environment.serverParameters.url + '/songs', { params: this.params })
      .pipe(
        map(songs => {
          return songs.filter(data => {
            // tslint:disable-next-line:triple-equals
            if (data.status != '2') { return data; }
          });
        })
      );
  }
  // ------------------------------------------------------------------------
}
