import { Component, OnInit, Input } from '@angular/core';
import { Song } from '../../models/song';


@Component({
  selector: 'app-view-song',
  templateUrl: './view-song.component.html',
  styleUrls: ['./view-song.component.css']
})
export class ViewSongComponent implements OnInit {

  @Input()
  song: string

  constructor() { }

  ngOnInit() {
  }

}
